import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";


function Banner(){
let [realNum, setRealNum] = useState(1)
let [fNum1, setFNum1] = useState(2)
let [fNum2, setFNum2] = useState(3)


useEffect(() => {
// console.log('start: ' + realNum)
setFNum1(null)
setFNum2(null)
    setTimeout(() => {
        if(realNum === 3){
            setRealNum(1)} else {
                setRealNum(realNum + 1)}
        
        
        // document.getElementById('banner11').classList.remove(`photo_banner${newestNum}`)
        // document.getElementById('banner11').classList.add(`photo_banner${newestNum}`)
        // console.log('end: ' + realNum) 
    }, 15000)


},[realNum])

// setInterval(()=> {
//     console.log(realNum)
//     if(realNum === 3){
//         setRealNum(1)
//     } else {
//         setRealNum(realNum + 1)
//     }
//     console.log(realNum)
//     console.log(`photo_banner${realNum}`)
// }, 15000)

    return(
    <>
    <div id="banner11" style={{position:'relative'}} className={`photo_holder  photo_banner${fNum1} photo_banner${fNum2} photo_banner${realNum}`}>
                <div className="screen"></div>
                <div style={{flexDirection:"column"}}className="photo_banner_text">
                    <h1>Chiropractic Solutions for Comprehensive Accident Recovery</h1>
                    <h2 on>If you have pain, we can help!</h2>
                    <NavLink to='/auto-accidents'>Learn More</NavLink>

                </div>
            </div>
    </>)
}


export default Banner
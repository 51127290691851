import React from "react";

function About_Us(props) {
  let { left, right } = props;

  let Sam =
    "https://lh7-rt.googleusercontent.com/docsz/AD_4nXdCjA5gDndnVOWP-i4MyLUraMh6_KNa2pICumE6o7N_gaOoi-gicGBMLDc6CGWMQke8S4HEowZZPWtYbjbghDz6TTZ0rOT2B_gUCVxheh8Hx93nzNnromG7mZk7Z5DCtk8X_2oy?key=ykxwldzJkQ4yx0pFLTxaWXDB";
  let Dante =
    "https://lh7-rt.googleusercontent.com/docsz/AD_4nXdlMWt5ERg59OeD2c33W0s7LCfbdmXXI9osN7ElLMQ6NOVONuqfs8QHUl4dfeGaUStB75qV3LxhE9wjPfFr9BBpTFvfuTPcFB68t5ZHqmXB4o4RtErDAwJUOoRbzIngMvokzajNpA?key=ykxwldzJkQ4yx0pFLTxaWXDB";

  return (
    <>
      <div
        style={{
          alignItems: "center",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="about_office">
          <div className="ao_container">
            <img src={Dante} alt="" />
          </div>
          <div className="ao_container ao_text">
            <h2>DANTE GURGIGNO, DC </h2>
            <span style={{ width: "90%", maxWidth: "none" }}>
              <p>
                Dr. Dante Gurgigno actively takes time to listen to his patients and treat them in accordance with their unique needs because he believes in fostering a healthy and positive lifestyle. Since earning his degree from Life University in June of 2000, he has made a significant contribution to the healthcare industry. 
                <br /><br />
                He has traveled the nation throughout the years to continue his studies to be up to date on new techniques and methods. He is aware of how crucial it is for professionals with different philosophies of treatment to collaborate and co-manage patients for the best possible outcome. 
                <br /><br />
                When he’s not working or publicly speaking to his community, you can find him enjoying time with his family.
              </p>
            </span>
          </div>
        </div>
        <span style={{background:'rgba(0,0,0,.4)', width:'100%', height:'2px', maxWidth:'1100px'}}></span>
        <div className="about_office about_office_reverse">
        <div className="ao_container ao_text">
            <h2>Sam Kashani, DC </h2>
            <span style={{ width: "90%", maxWidth: "none" }}>
              <p>
              Dr. Sam Kashani went on to complete his Doctor of Chiropractic from Life University in 2001. He is dedicated to educating and serving his community,and committed to helping each of his patients discover their full potential with the knowledge he has gained over the years. 
              <br /><br />
              He is entirely committed to his career in chiropractic and wellness, and he eagerly awaits the chance to help you on your road to health and wellness. 
              <br /><br />
              When he is not working he's very active in his community, surrounding areas, speaks fluent Farsi and loves Persian culture.
              </p>
            </span>
          </div>
          <div className="ao_container">
            <img src={Sam} alt="" />
          </div>
          
        </div>
        
        {/* <hr /> */}
      </div>
    </>
  );
}

export default About_Us;

import React, {useEffect, useState} from 'react'
// import { BrowserRouter } from 'react-router-dom';
import ChiroPage from './ChiroPage';

import './App.css';
import './App2.css';
import NavBar from './NavBar';

function App() {
  // let [patientForm, setPatientForm] = useState()
  // let [piForm, setPiForm] = useState()
  // let [transform, setTransform] = useState('translateX(100vw)')

  useEffect(() =>{
//     try {
//       console.log('chirp')
// let pForm = document.getElementById('pant-form')
// console.log(pForm)
// setPatientForm(pForm)
// localStorage.setItem('patientForm', JSON.stringify(pForm) )
// pForm.remove()
// console.log(patientForm)
//     } catch (error) {
//       console.log(error)
//     }
// // *******************************
// try {
//   let pIForm = document.getElementById('pi-form')

//   setPiForm(pIForm)
//   pIForm.remove()


// } catch (error) {
//   console.log(error)
// }







// setTransform('translateX(-100%)')
}, [])

  return (
    
    <div className="App">
      {/* <div style={{'height': '70px', width: '100vw', display:'flex', alignItems: 'center', alignContent: 'center', justifyContent:'flex-start', overflow:'hidden', position:'relative'}}>
        <div id="" style={{position:'absolute',color:'#2a1f7e', fontWeight:'bold',fontSize: 'clamp(20px, 2.5vw, 26px)', width:'100%', textAlign:'left' }}>
        <span>Hablamos Espanol!</span>
        <span><br />Call now to schedule your appointment! (770) 800- 2070</span>
        </div>
      </div> */}
      <ChiroPage />
    </div>
  );
}

export default App;

import React from "react";
import Banner1 from "./Banner1"
import InfoSection from "./InfoSection";


let data = [
    {title1:`What is a Platelet-Rich Plasma (PRP) injection?`, 
     text:[`This injection leverages the body’s natural healing properties, as platelets contain growth factors that promote tissue repair and regeneration. For accident injury victims, PRP injections can help reduce pain, accelerate recovery, and improve mobility by stimulating the healing of damaged tissues, such as ligaments, tendons, and muscles. It’s a minimally invasive option that can complement traditional therapies and enhance overall recovery outcomes.`]},
    {title1:`What is a trigger point injection?`, 
     text:[`These injections typically contain local anesthetic and may include anti-inflammatory properties.For accident injury victims, trigger point injections can provide immediate relief from chronic pain, decrease muscle tension, and improve mobility. By targeting these painful areas, the treatment helps restore function and enhances overall recovery, making it a valuable option for those dealing with post-injury pain.`]}
]
let photo = 'https://media.fshoq.com/images/207/woman-measuring-the-pressure-at-the-doctor-207-small.jpg'


function Medical(){




    return(
    <>
    <Banner1 text1={'Medical'} image={photo}/>
    <InfoSection  info={data}/>
    
    </>)
}

export default Medical